<template>
    <section id="industries-section">
        <div class="wrap industrial">
            <div class="industrial-form">
                <h2>More than 10 years in the welding, insulation, scaffolding, engineering and we are still looking for new challenges.</h2>
                <p>Whether you are building drilling platform, hull or maintain power plant we are able to deliver highly skilled, efficient and structured production teams suited to your needs.</p>
                <input v-model="landingEmail" placeholder="Email address" type="email">
                <button v-on:click='sendLandingEmail(landingEmail)'>Let us contact you</button>
            </div>
            <div class="industrial-info">
                <div class="industry one">
                    <div class="industry-icon"></div>
                    <h3>Oil and Gas <br>industry</h3>
                    <ul>
                        <li class="industry-option">conversion and repairs</li>
                        <li class="industry-option">onshore and offshore</li>
                        <li class="industry-option">decommission</li>
                        <li class="industry-option">prefabrication</li>
                        <li class="industry-option">FPSO</li>
                    </ul>
                </div>
                <div class="industry two">
                    <div class="industry-icon"></div>
                    <h3>Petrochemical <br>industry</h3>
                    <ul>
                        <li class="industry-option">pressure vessels</li>
                        <li class="industry-option">prefabrication</li>
                        <li class="industry-option">instalation</li>
                        <li class="industry-option">hookups</li>
                        <li class="industry-option">repairs</li>
                    </ul>
                </div>
                <div class="industry three">
                    <div class="industry-icon"></div>
                    <h3>Shipbuilding</h3>
                    <ul>
                        <li class="industry-option">assembly and outfitting</li>
                        <li class="industry-option">ship superstructure</li>
                        <li class="industry-option">decommission</li>
                        <li class="industry-option">repairs</li>
                        <li class="industry-option">hull</li>
                    </ul>
                </div>
                <div class="industry four">
                    <div class="industry-icon"></div>
                    <h3>Wind power <br>industry</h3>
                    <ul>
                        <li class="industry-option">floating constructions</li>
                        <li class="industry-option">onshore and offshore</li>
                        <li class="industry-option">fundaments</li>
                        <li class="industry-option">monopiles</li>
                        <li class="industry-option">jackets</li>
                    </ul>
                </div>
                <div class="industry five">
                    <div class="industry-icon"></div>
                    <h3>Steel structures <br>Piping instalations</h3>
                    <ul>
                        <li class="industry-option">prefabrication</li>
                        <li class="industry-option">pipelines</li>
                        <li class="industry-option">bridges</li>
                        <li class="industry-option">cranes</li>
                        <li class="industry-option">tanks</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Industries',

    data() {
        return {
            landingEmail: '',
        }
    },

    methods: {
        sendLandingEmail: function(el) {
           
        const regEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

      if (regEmail.test(el)) {
             const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ data: {Email: this.landingEmail} })
            };
            fetch(this.$mainlink + this.$landingemailslink, requestOptions)
                .then(async response => {
                const data = await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                this.postId = data.id;
                })
                .catch(error => {
                this.errorMessage = error;
                console.error('There was an error!', error);
                });

                this.landingEmail = ""
        } else {
            console.log(el + " is not valid email")
        }
        }, 
    }

}

</script>

<style src='./Industries.css'>

</style>
