<template>
  <Menu/>
  <router-view/>
  
</template>

<script>
import Menu from '@/views/Menu/Menu.vue'


export default {
  name: 'App',
  components: {
    Menu
  }
}
</script>

<style>
@import './App.css';
</style>