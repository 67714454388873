<template>
    <article id="full-gdpr" v-if="dataReady">
            <section class="gdpr-header">
                <span>Aktualizacja z dnia {{ thisIsGdpr.updatedAt }}</span>
                <h1>{{ thisIsGdpr.Klauzula }}</h1>
            </section>
            <section class="gdpr-content">
                <Markdown :source="thisIsGdpr.Tresc" :breaks="true" class="full-gdpr-body" />
            </section>
    </article>
    <p v-else>Content loading</p>

    <Footer/>
</template>

<script>
    import Footer from '@/components/Footer/Footer.vue';
    import Markdown from 'vue3-markdown-it';

    export default {
        name: 'Gdpr',
        props: ['slug'],
        components: {
            Footer,
            Markdown
        },

        data() {
            return{
                thisGdprSlug: this.slug,
                thisIsGdpr: [],
                storyFromStory: null,
                dataReady: false
            }
        },

    async mounted() {
        await fetch(this.$mainlink + this.$gdprslink + this.$gdprlink + this.thisGdprSlug)
        .then(res => res.json())
        .then(data => this.thisIsGdpr = data)
        .catch(err => console.log(err.message));

        const storyJsonText = JSON.stringify(this.thisIsGdpr);
        const storyJsonParse = JSON.parse(storyJsonText);           
        this.thisIsGdpr = storyJsonParse[0];
        this.storyFromGdpr = storyJsonParse[0].Tresc;

        this.dataReady = true;
    },


  updated() {
    // Wait for the DOM to update with v-html content
    this.$nextTick(() => {
      const tables = document.querySelectorAll('table');
      
      tables.forEach(table => {
        // Get the first row in the table header
        const firstHeaderRow = table.querySelector('thead tr');
        if (firstHeaderRow) {
          // Get the first cell in the header row
          const firstCell = firstHeaderRow.querySelector('th');
          if (firstCell) {
            // Set the span attribute to 2
            firstCell.setAttribute('colspan', '2'); // Use 'colspan' instead of 'span'
          }
        }
      });
    });
  }
};

</script>

<style scoped>
@import './gdpr.css';
</style>