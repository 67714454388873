<template>
    <section id="services">
        <section class="wrap service">
            <div id="service-disc">
                <h1 class="service">Insulation</h1>
                <p class="service">Our skilled insulation operators teams provide services of installation, prefabrication, repairment and removal of all types and classes of insulation. In order to protect installations and avoid energy losses our employees are experienced in working with a wide spectrum of insulation materials, i.e. Pyrogel, Foamglass, Wool, Armaflex, Kaiflex, PUR, Sound Stop, Favuseal etc. All to provide best quality for thermal, acoustic and fire protection solutions.</p>
                <p class="service">To ensure comprehensive service, we also provide specialized prefabrication professionals like workshop tinsmiths, box measurement operators, survey operators, insulation warehouse operators. Who all working together in order to prepare elements like box, hoods etc. (stainless steel and aluminium to non-metallic) to be ready for on-site installation at decks, pipes and tanks.</p>
                <p class="service">Available personnel:
                    <ul>
                        <li>Insulation foreman</li>
                        <li>Insulation operators (thermal, acoustic, fire protection)</li>
                        <li>Workshop tinsmith</li>
                        <li>Box measurement operators</li>
                        <li>Survey operators</li>
                        <li>Insulation warehouse operators</li>
                    </ul>    
                </p>
            </div>
            <div id="service-photos">
                <img class="service-photo" :src="images.service1st" crossorigin=""/>
                <img class="service-photo" :src="images.service2nd" crossorigin=""/>
            </div>
        </section>
    </section>
    <TrustedUs/>
    <CallUs/>
    <Footer/>
</template>

<script>
    import TrustedUs from '@/components/TrustedUs/TrustedUs.vue'
    import CallUs from '@/components/CallUs/CallUs.vue'
    import Footer from '@/components/Footer/Footer.vue'
    
    export default {
        name: 'Insulation',
        components: { 
            TrustedUs,
            CallUs,
            Footer 
        },
        
        data() {
            return {
                    images: {
                        service1st: require('../../assets/Services/Insulation services on the FPSO.png'),
                        service2nd: require('../../assets/Services/Insulation servicies on the oil rig platform.png')
                },
            }
        }
    }
</script>

<style>
@import './Services.css';
</style>