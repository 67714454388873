<template>
    <div class="img-modal-body" v-show="open" @click="$emit('close')">
        <div class="img-modal-content">
            <slot/>
        </div>
    </div>
</template>

<script>

export default {

    props: {
        open: {
            type: Boolean,
            required: true
        }
    }

}

</script>

<style>
@import './ImgModal.css';
</style>