<template>
    <section id="recent-news">
        <div class="wrap news">
            <h2>Latest news</h2>
            <div class="short-news" v-if="dataReady">
                <div v-for="story in lastStory" :key="story.id" class="last-story">
                    <div class="crop-story-img" v-if="story.MainVisualContent">
                        <img :src="story.MainVisualContent.formats.medium.url" crossorigin=""/>
                    </div>
                    <p v-else></p> 
                    <span class="story-date"> {{ story.Date }} </span>
                    <h3 class="news-title"> {{ story.Title }} </h3>
                    <Markdown :source="storyBody" :breaks="true" id="crop-story-body" />

                    <router-link :to="{ name: 'Story', params: {slug: story.Slug} }" class="news-link">
                        <img class="arrow" :src="images.arrow" crossorigin=""/>
                    </router-link>
                </div>

                    <div v-for="news in newses" :key="news.id" class="latest-card">
                        <span class=""> {{ news.Date }} </span>
                        <h3 class="news-title"> {{ news.Title }}</h3>
                    <router-link :to="{ name: 'Story', params: {slug: news.Slug} }" class="news-link">
                        <img class="arrow" :src="images.arrow" crossorigin=""/>
                    </router-link>
                    </div>
            </div>
            <router-link :to="{ name: 'News' }" class="go-to-news-center">Go to news center</router-link>
        </div>
    </section>
</template>

<script>
    import Markdown from 'vue3-markdown-it';

    export default {

        name: 'RecentNews',
        components: {
            Markdown
        },

        data(){
            return{
                newses: [],
                lastStory: [],
                dataReady: false,
                images: {
                    arrow: require('../../assets/icons/Arrow.svg')
                },
                storyBody: null,
                widthOfWindow: 99,
            }
    
        },

        async mounted() {
            await fetch(this.$mainlink + this.$storieslink + this.$latestlink)
                .then(res => res.json())
                .then(data => this.newses = data)
                .catch(err => console.log(err.message));

            await fetch(this.$mainlink + this.$storieslink + this.$laststorylink)
                .then(res => res.json())
                .then(data => this.lastStory = data)
                .catch(err => console.log(err.message));

            const storyJsonText = JSON.stringify(this.lastStory)
            const storyJsonParse = JSON.parse(storyJsonText)
            const storyJson = (storyJsonParse[0])
            const length = 175
            
            if( storyJson != null) {
                const string = storyJson.Story

                if (string.length < length) {
                    this.storyBody = string

                } else {
                    var shortBody = string.substring(0, length)
                    shortBody = shortBody.substring(0, Math.min(shortBody.length, shortBody.lastIndexOf(" "))) + " [...]"
                    this.storyBody = shortBody
                }

            } else {
                console.log("Content is loading")
                this.storyBody = "Content is loading"
            }

            this.dataReady = true;
        },
        }
</script>

<style src="./RecentNews.css"></style>