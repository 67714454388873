<template>
    <article id="full-story" v-if="dataReady">
            <section class="story-header">
                <span>{{ thisIsStory.Date }}</span>
                <h1>{{ thisIsStory.Title }}</h1>
            </section>
            <section class="story-content">
                <Markdown :source="thisIsStory.Story" :breaks="true" class="full-story-body" />
                <aside v-if="thisIsStory.MainVisualContent" class="story-img">
                        <h2>Photos</h2>
                        <img class="this-story-main-photo" :src="thisIsStory.MainVisualContent.url" :alt="thisIsStory.MainVisualContent.alternativeText" crossorigin="" @click="sendImageUrl(thisIsStory.MainVisualContent.url)" />
                        <div v-for="photo in thisIsStory.SidePhotos" :key="photo.id">
                            <img  class="this-story-side-photo" :src="photo.url" :alt="photo.alternativeText" crossorigin="" @click="sendImageUrl(photo.url)"/>
                        </div>
                </aside>
            <section class="story-videos"></section>
            <section class="story-links"></section>
            <section class="story-files"></section>
            </section>
            <ImgModal :open="isOpen" @click="isOpen = !isOpen">
                <img class="image-for-modal" :src="this.imageUrl" />
            </ImgModal>
    </article>
    <p v-else>Content loading</p>

    <Footer/>
</template>

<script>
    import { ref } from "vue"; 
    import Footer from '@/components/Footer/Footer.vue';
    import ImgModal from '@/components/ImgModal/ImgModal.vue';
    import Markdown from 'vue3-markdown-it';

    export default {
        name: 'Story',
        props: ['slug'],
        components: {
            Footer,
            Markdown,
            ImgModal
        },
        
        setup() {
          const isOpen = ref(false)
          return { isOpen }  
        },

        data() {
            return{
                thisStorySlug: this.slug,
                thisIsStory: [],
                storyFromStory: null,
                dataReady: false,
                imageUrl: null,
                images: {
                    calendar: require('../../assets/icons/calendar.svg')
                }
            }
        },

        methods: {
            sendImageUrl(url) {
                this.imageUrl = url;
                this.isOpen = true;
            },
        },

        async mounted() {
                await fetch(this.$mainlink + this.$storieslink + this.$thestorylink + this.thisStorySlug)
                    .then(res => res.json())
                    .then(data => this.thisIsStory = data)
                    .catch(err => console.log(err.message));
            
            const storyJsonText = JSON.stringify(this.thisIsStory)
            const storyJsonParse = JSON.parse(storyJsonText)           
            this.thisIsStory = storyJsonParse[0];
            this.storyFromStory = storyJsonParse[0].Story;

            this.dataReady = true;
        },

    }
</script>

<style>
@import './Story.css';
</style>