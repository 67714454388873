<template>
    <section id="hero-section">
        <div class="wrap hero">
            <h1 class="hero">People co-create value</h1>
            <h2 class="hero">SEC supplies top-class specialists to accelerate your projects, 
                prevent delays and achieve desired quality</h2>
            <a class="hero"><router-link :to="{ name: 'Contact' }">Tell us who you need</router-link></a>
            <p class="hero">Offshore / Onshore / Shipbuilding / Oil and gas / Wind power / Decommissioning</p>
        </div>
    </section>
    <Experts/>
    <TrustedUs/>
    <Industries/>
    <RecentNews/>
    <CallUs/>
    <Footer/>
</template>

<script>
    import Experts from '@/components/Experts/Experts.vue'
    import TrustedUs from '@/components/TrustedUs/TrustedUs.vue'
    import Industries from '@/components/Industries/Industries.vue'
    import RecentNews from '@/components/RecentNews/RecentNews.vue'
    import CallUs from '@/components/CallUs/CallUs.vue'
    import Footer from '@/components/Footer/Footer.vue'

    export default {
        name: 'LandingPage',
        components: {
            Experts,
            TrustedUs,
            Industries,
            RecentNews,
            CallUs,
            Footer
        }
    }
</script>

<style>
@import './LandingPage.css';
</style>