<template>
    <section id="experts-section">
        <div class="wrap experts">
            <div class="experts-info">
                <h2>Experts in every aspect of the production cycle</h2>
                <p>World-class production teams, on demand. Teams that can design,
                    build and deliver your vision in the most efficient way.</p>
            </div>
            <div class="experts-figures">
                <p><span class="figure">10+</span><span class="description">years of SEC<br> on the market</span></p>
                <p><span class="figure">60+</span><span class="description">delivered<br> projects</span></p>
                <p><span class="figure">3.8+</span><span class="description">MM man-hours<br> executed</span></p>
                <p><span class="figure">600+</span><span class="description">experienced<br> professionals</span></p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Experts',

    created () {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        counters(){
        const figures = document.querySelectorAll('.figure');
        

        figures.forEach(figure=> {
            const updateCount = () => {
                const target = figure.getAttribute('data-target');

                console.log(target);
            }
            updateCount();
        })

        }

    }
}
</script>

<style src='./Experts.css'>

</style>
