<template>
    <section id="references-section">
        <section class="wrap references">
            <h1 class="references">Reference letters</h1>
            <div id='filters-section'></div>
            <section id="references-body" v-if="dataReady">
                <div v-for="reference in allReferences" :key="reference.id" class="reference">
                    <div class="reference-letter" v-if="reference.Photo">
                        <span class="reference-letter" v-if="reference.Photo.formats.small">
                        <img :src="reference.Photo.formats.small.url" crossorigin="" @click="sendImageUrl(reference.Photo.url)" />
                        <a class="reference-pdf" :href="reference.Pdf.url" target="_blank" download><img class="arrow-pdf" :src="images.arrow" crossorigin=""/></a>
                        </span>
                    </div>
                    <p v-else> </p>
                    <div class="reference-info">
                        <div class="reference-period">
                            <p> {{reference.StartYear}} <span> - </span> {{reference.EndYear}} </p>
                        </div>
                        <span class="reference-clients" v-for="client in reference.Clients" :key="client.id">{{client.Client}}</span>
                        <div class="reference-projects">
                            <h2>Project</h2>
                            <ul>
                                <li v-for="project in reference.Projects" :key="project.id">
                                    {{project.Project}}
                                </li>
                            </ul>
                        </div>
                        <div class="reference-tags">
                            <h2>Services</h2>
                            <ul>
                                <li v-for="tag in reference.Tags" :key="tag.id">
                                    {{tag.Tag}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <ImgModal :open="isOpen" @click="isOpen = !isOpen">
            <img class="image-for-modal" :src="this.imageUrl" />
        </ImgModal>
    </section>
    <TrustedUs/>
    <CallUs/>
    <Footer/>
</template>

<script>
    import { ref } from "vue"; 
    import TrustedUs from '@/components/TrustedUs/TrustedUs.vue'
    import CallUs from '@/components/CallUs/CallUs.vue'
    import Footer from '@/components/Footer/Footer.vue'
    import ImgModal from '@/components/ImgModal/ImgModal.vue'
    
    export default {
        name: 'References',
        components: { 
            TrustedUs,
            CallUs,
            Footer,
            ImgModal
        },
                data(){
            return{
                allReferences: [],
                dataReady: false,
                imageUrl: null,
                images: {
                    arrow: require('../../assets/icons/Download Arrow.svg')
                },
            }    
        },

        setup() {
          const isOpen = ref(false)
          return { isOpen }  
        },

        methods: {
            sendImageUrl(url) {
                console.log(url);
                this.imageUrl = url;
                this.isOpen = true;
            },
        },

        async mounted() {
            await fetch(this.$mainlink + this.$referenceslink)
                    .then(res => res.json())
                    .then(data => this.allReferences = data)
                    .catch(err => console.log(err.message));
            
            this.dataReady = true;
        }
    }
</script>

<style>
@import './References.css';
</style>