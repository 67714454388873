<template>
    <article id="full-Article" v-if="dataReady">
            <section class="Article-header">
                <span>{{ thisIsStory.Date }}</span>
                <h1>{{ thisIsStory.Title }}</h1>
            </section>
            <section class="Article-content">
                <Markdown :source="thisIsStory.Body" :breaks="true" class="full-Article-body" />
                <aside v-if="thisIsStory.HeaderPhoto" class="Article-img">
                        <h2>Photos</h2>
                        <img class="this-Article-main-photo" :src="thisIsStory.HeaderPhoto.url" :alt="thisIsStory.HeaderPhoto.alternativeText" crossorigin="" @click="sendImageUrl(thisIsStory.HeaderPhoto.url)" />
                        <div v-for="photo in thisIsStory.Photos" :key="photo.id">
                            <img  class="this-Article-side-photo" :src="photo.url" :alt="photo.alternativeText" crossorigin="" @click="sendImageUrl(photo.url)"/>
                        </div>
                </aside>
            <section class="Article-videos"></section>
            <section class="Article-links"></section>
            <section class="Article-files"></section>
            </section>
            <ImgModal :open="isOpen" @click="isOpen = !isOpen">
                <img class="image-for-modal" :src="this.imageUrl" />
            </ImgModal>
    </article>
    <p v-else>Content loading</p>

    <Footer/>
</template>

<script>
    import { ref } from "vue"; 
    import Footer from '@/components/Footer/Footer.vue';
    import ImgModal from '@/components/ImgModal/ImgModal.vue';
    import Markdown from 'vue3-markdown-it';

    export default {
        name: 'Article',
        props: ['slug'],
        components: {
            Footer,
            Markdown,
            ImgModal
        },
        
        setup() {
          const isOpen = ref(false)
          return { isOpen }  
        },

        data() {
            return{
                thisStorySlug: this.slug,
                thisIsStory: [],
                storyFromStory: null,
                dataReady: false,
                imageUrl: null,
                images: {
                    calendar: require('../../assets/icons/calendar.svg')
                }
            }
        },

        methods: {
            sendImageUrl(url) {
                this.imageUrl = url;
                this.isOpen = true;
            },
        },

        async mounted() {
                await fetch(this.$mainlink + this.$responsibleslink + this.$thearticlelink + this.thisStorySlug)
                    .then(res => res.json())
                    .then(data => this.thisIsStory = data)
                    .catch(err => console.log(err.message));
            
            const storyJsonText = JSON.stringify(this.thisIsStory)
            const storyJsonParse = JSON.parse(storyJsonText)           
            this.thisIsStory = storyJsonParse[0];
            this.storyFromStory = storyJsonParse[0].Body;

            this.dataReady = true;
        },

    }
</script>

<style>
@import './Article.css';
</style>