<template>
    <section id="news-center">
        <section class="wrap stories">
            <h1 class="stories">Responsible SEC</h1>
            <div id='news-filters-section'><p>In today's business world, it is not enough to be profitable - companies must also give back to the communities in which they operate. They must be socially responsible. At SEC Pomorze, we believe in this mission. We support the local community, especially young athletes and sports clubs. We do this because we believe that healthy young people are the future of our society. They are our hope for a better tomorrow. By supporting them, we are investing in the future of our community. And that is something we can all feel good about.</p></div>
            <section id="news-section" v-if="dataReady">
                <div v-for="theNews in allNews" :key="theNews.id" class="all-news-info">
                    <div class="left-news">
                        <h3 class="all-news-title"> {{ theNews.Title }}</h3>
                        <span class="all-news-date"> {{ theNews.Date }} </span>
                        <Markdown :source="theNews.Body" :breaks="true" id="crop-all-news-body" />
                    </div>
                    <div class="all-news-photo" v-if="theNews.HeaderPhoto">
                    <!--  -->
                    <span v-if="theNews.HeaderPhoto.formats.small">
                       <img :src="theNews.HeaderPhoto.formats.small.url" crossorigin=""/>
                    </span>
                    </div>
                    <p v-else> </p> 
                    <router-link :to="{ name: 'Article', params: {slug: theNews.Slug} }" class="news-link">
                        <img class="arrow" :src="images.arrow" crossorigin=""/>
                    </router-link>
                </div>
            </section>
        </section>
    </section>
    <TrustedUs/>
    <CallUs/>
    <Footer/>
</template>

<script>
    import TrustedUs from '@/components/TrustedUs/TrustedUs.vue'
    import CallUs from '@/components/CallUs/CallUs.vue'
    import Footer from '@/components/Footer/Footer.vue'
    import Markdown from 'vue3-markdown-it';
    
    export default {
        name: 'NewsCenter',
        components: { 
            TrustedUs,
            CallUs,
            Footer,
            Markdown
        },
                data(){
            return{
                allNews: [],
                newsBody:[],
                dataReady: false,
                images: {
                    arrow: require('../../assets/icons/Arrow.svg')
                },
            }
    
        },

        async mounted() {
            await fetch(this.$mainlink + this.$responsibleslink + this.$allstorieslink)
                    .then(res => res.json())
                    .then(data => this.allNews = data)
                    .catch(err => console.log(err.message));
            
            const storyJsonText = JSON.stringify(this.allNews)
            const storyJsonParse = JSON.parse(storyJsonText)
            const length = 450
           
              if( storyJsonParse != null) {
                for (let i = 0; i < storyJsonParse.length; i++) {

                const string = storyJsonParse[i].Body

                if (string.length < length) {
                    this.allNews[i].Body = string

                } else {
                    var shortAllNewsBody = string.substring(0, length)
                    shortAllNewsBody = shortAllNewsBody.substring(0, Math.min(shortAllNewsBody.length, shortAllNewsBody.lastIndexOf(" "))) + " [...]"
                    this.allNews[i].Body = shortAllNewsBody
                }
                }
                }
            this.dataReady = true;

        }


    }
</script>

<style>
@import './ResponsibleSEC.css';
</style>