<template>
    <section id="news-center">
        <section class="wrap stories">
            <h1 class="stories">News center</h1>
            <div id='news-filters-section'></div>
            <section id="news-section" v-if="dataReady">
                <div v-for="theNews in allNews" :key="theNews.id" class="all-news-info">
                    <div class="left-news">
                        <h3 class="all-news-title"> {{ theNews.Title }}</h3>
                        <span class="all-news-date"> {{ theNews.Date }} </span>
                        <Markdown :source="theNews.Story" :breaks="true" id="crop-all-news-body" />
                    </div>
                    <div class="all-news-photo" v-if="theNews.MainVisualContent">
                    <!--  -->
                    <span v-if="theNews.MainVisualContent.formats.small">
                       <img :src="theNews.MainVisualContent.formats.small.url" crossorigin=""/>
                    </span>
                    </div>
                    <p v-else> </p> 
                    <router-link :to="{ name: 'Story', params: {slug: theNews.Slug} }" class="news-link">
                        <img class="arrow" :src="images.arrow" crossorigin=""/>
                    </router-link>
                </div>
            </section>
        </section>
    </section>
    <TrustedUs/>
    <CallUs/>
    <Footer/>
</template>

<script>
    import TrustedUs from '@/components/TrustedUs/TrustedUs.vue'
    import CallUs from '@/components/CallUs/CallUs.vue'
    import Footer from '@/components/Footer/Footer.vue'
    import Markdown from 'vue3-markdown-it';
    
    export default {
        name: 'NewsCenter',
        components: { 
            TrustedUs,
            CallUs,
            Footer,
            Markdown
        },
                data(){
            return{
                allNews: [],
                newsBody:[],
                dataReady: false,
                images: {
                    arrow: require('../../assets/icons/Arrow.svg')
                },
            }
    
        },

        async mounted() {
            await fetch(this.$mainlink + this.$storieslink + this.$allstorieslink)
                    .then(res => res.json())
                    .then(data => this.allNews = data)
                    .catch(err => console.log(err.message));
            
            const storyJsonText = JSON.stringify(this.allNews)
            const storyJsonParse = JSON.parse(storyJsonText)
            const length = 450
           
              if( storyJsonParse != null) {
                for (let i = 0; i < storyJsonParse.length; i++) {

                const string = storyJsonParse[i].Story

                if (string.length < length) {
                    this.allNews[i].Story = string

                } else {
                    var shortAllNewsBody = string.substring(0, length)
                    shortAllNewsBody = shortAllNewsBody.substring(0, Math.min(shortAllNewsBody.length, shortAllNewsBody.lastIndexOf(" "))) + " [...]"
                    this.allNews[i].Story = shortAllNewsBody
                }
                }
                }
            this.dataReady = true;

        }


    }
</script>

<style>
@import './NewsCenter.css';
</style>