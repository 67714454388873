<template>
    <section id="services">
        <section class="wrap service">
            <div id="service-disc">
                <h1 class="service">Scaffolding</h1>
                <p class="service">Our scaffolding teams lead by discipline foremen are able to erect, dismantle and remodel of scaffolding of any type i.e. module systems, driving scaffoldings, roof systems, elevation scaffoldings etc. SEC scaffolding teams are fully equipped and experienced to work with system like:
                    <ul>
                        <li>Haki / Hanging Haki</li>
                        <li>Delta</li>
                        <li>Rama Layher / Layher</li>
                        <li>+8</li>
                        <li>Alustar</li>
                        <li>Monzon</li>
                        <li>Peri</li>
                        <li>Burton</li>
                    </ul>    
                </p>
            </div>
            <div id="service-photos">
                <img class="service-photo" :src="images.service1st" crossorigin=""/>
                <img class="service-photo" :src="images.service2nd" crossorigin=""/>
            </div>
        </section>
    </section>
    <TrustedUs/>
    <CallUs/>
    <Footer/>
</template>

<script>
    import TrustedUs from '@/components/TrustedUs/TrustedUs.vue'
    import CallUs from '@/components/CallUs/CallUs.vue'
    import Footer from '@/components/Footer/Footer.vue'
    
    export default {
        name: 'Scaffolding',
        components: { 
            TrustedUs,
            CallUs,
            Footer 
        },

        data() {
            return {
                    images: {
                        service1st: require('../../assets/Services/Scaffolding servicies on the gas plant.png'),
                        service2nd: require('../../assets/Services/Scaffolding servicies on the oil rig platform.png')
                },
            }
        }
    }
</script>

<style>
@import './Services.css';
</style>