<template>
    <section id="call-us">
        <div class="wrap call">
            <h2>Call us directly now</h2>
            <h3>and let’s build your project together</h3>
            <a href="tel:+48603085610">+48 603 085 610</a>
        </div>

    </section>
</template>

<script>
    export default {
        name: 'CallUs'
    }
</script>

<style src="./CallUs.css"></style>
