<template>
    <footer>
        <div class="wrap foot">
            <img :src="images.SECLogo" alt="" crossorigin=""/>
            <div class="wrap-content">
                <div class="footer-content">
                    <h3>SERVICES</h3>
                    <ul>
                        <li><router-link :to="{ name: 'Insulation' }">Insulation</router-link></li>
                        <li><router-link :to="{ name: 'Scaffolding' }">Scaffolding</router-link></li>
                        <li><router-link :to="{ name: 'Welding' }">Welding</router-link></li>
                    </ul>
                </div>

                <div class="footer-content">
                    <h3>COMPANY</h3>
                    <ul>
                        <li><router-link :to="{ name: 'News' }">News center</router-link></li>
                        <li><router-link :to="{ name: 'References' }">References</router-link></li>
                        <li><router-link :to="{ name: 'Responsible' }">Responsible SEC</router-link></li>
                        <li><router-link :to="{ name: 'Contact' }">Contact us</router-link></li>
                        <li><router-link :to="{ name: 'PrivacyStatment' }">Privacy statment</router-link></li>
                    </ul>
                </div>

                <div class="footer-content">
                    <h3 class="location">POLAND</h3>
                    <ul>
                        <li>SEC Pomorze Sp. z o.o.</li>
                        <li>Twarda 12</li>
                        <li>80-871 Gdańsk</li>
                        <li>NIP 957 104 71 98</li>
                    </ul>
                </div>

                <div class="footer-content">
                    <h3 class="location">NORWAY</h3>
                    <ul>
                        <li>SEC Norge AS</li>
                        <li>Amlievegen 13</li>
                        <li>5416 Stord</li>
                        <li>NIP 898 125 882</li>
                    </ul>
                </div>

                <div class="footer-content">
                    <h3 class="location">GERMANY</h3>
                    <ul>
                        <li>SEC GmbH</li>
                        <li>Kurfürstendamm 226</li>
                        <li>10719 Berlin</li>
                        <li>HRB 248467B</li>
                    </ul>
                </div>

                <div class="footer-content">
                    <h3 class="location">UNITED KINGDOM</h3>
                    <ul>
                        <li>SEC GB&NI Ltd</li>
                        <li>18 London Road, Portsmouth</li>
                        <li>England, PO2 0LH, UK</li>
                        <li>Company number 13763919</li>
                    </ul>
                </div>
            </div>


            <div class="footer-social">
                <a href="https://www.facebook.com/SECGrupa" class="social facebook"><img :src="images.Facebook" alt="" crossorigin=""/></a>
                <a href="https://www.linkedin.com/company/3334005/" class="social linkedin"><img :src="images.LinkedIn" alt="" crossorigin=""/></a>
            </div>

            <div class="copy">
                <p>© Copyrights 2021 SEC Pomorze.</p>
            </div>
            
            <a href="" class="designedBy">
                Designed by Product and Business Developer Marcin Piłat
            </a>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return{
            images:{
                SECLogo: require('../../assets/Branding/SEC logo red-white.svg'),
                Facebook: require('../../assets/icons/fb.svg'),
                LinkedIn: require('../../assets/icons/in.svg')
            }
        }

    }
}
</script>

<style src="./Footer.css"></style>