import { createApp } from 'vue'
import App from './App.vue'
import ScrollAnimation from './directives/scrollanimation'
import router from './router'



const sec = createApp(App).use(router);

sec.config.globalProperties.$storieslink = "/stories/"
sec.config.globalProperties.$responsibleslink = "/responsibles/"
sec.config.globalProperties.$thestorylink = "story/"
sec.config.globalProperties.$thearticlelink = "article/"
sec.config.globalProperties.$allstorieslink = "all/"
sec.config.globalProperties.$latestlink = "newest/"
sec.config.globalProperties.$laststorylink = "mainstory/"
sec.config.globalProperties.$landingemailslink = "/landing-emails/"
sec.config.globalProperties.$contactformlink = "/contacts/"
sec.config.globalProperties.$referenceslink = '/references/all'
sec.config.globalProperties.$mainlink = "https://blog.secgrupa.com/api"
sec.config.globalProperties.$gdprslink = "/gdprs/"
sec.config.globalProperties.$gdprlink = "gdpr/"

sec.directive('scrollanimation', ScrollAnimation);

sec.mount('#app');



