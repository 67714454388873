<template>
    <section id="services">
        <section class="wrap service">
            <div id="service-disc">
                <h1 class="service">Welding</h1>
                <p class="service">Years of experience of our personnel let us to provide comprehensive services for welding discipline. We provide welding personnel contributing to all stages of welding projects for industries such as Oil and Gas, Onshore, Offshore, Shipbuilding, Steel constructions and Pipelines, including blue-collar and white-collar personnel.</p>
                <p class="service">Our highly skilled welding teams, under supervision of welding foreman, have sufficient experience and certification in scope of welding methods like 111, 121, 131, 136,138, 141 etc. in wide range of thickness and dimeters. Including welding constructions and pipelines not only from carbon steel and stainless steel but also from demanding materials: Duplex, Super Duplex, High-alloy steel, 6Mo, Cunifer, aluminium and aluminium alloys.</p>
                <p class="service">To ensure comprehensive service, we closely collaborate with our clients and we are able to provide qualified SEC employees from engineering and inspection fields - engineers, constructions designers, inspectors, IWE/IWE and NDT operators.</p>            
                <p class="service">Available personnel:
                    <ul>
                        <li>Welders FCAW / TIG</li>
                        <li>Fitters Pipe / Construction</li>
                        <li>Steel metal workers</li>
                        <li>IWE / IWI personnel</li>
                        <li>Inspection personnel</li>
                        <li>NDT operators</li>
                    </ul>    
                </p>
            </div>
            <div id="service-photos">
                <img class="service-photo" :src="images.service1st" crossorigin=""/>
                <img class="service-photo" :src="images.service2nd" crossorigin=""/>
            </div>
        </section>
    </section>
    <TrustedUs/>
    <CallUs/>
    <Footer/>
</template>

<script>
    import TrustedUs from '@/components/TrustedUs/TrustedUs.vue'
    import CallUs from '@/components/CallUs/CallUs.vue'
    import Footer from '@/components/Footer/Footer.vue'
    
    export default {
        name: 'Welding',
        components: { 
            TrustedUs,
            CallUs,
            Footer 
        },

        data() {
            return {
                    images: {
                        service1st: require('../../assets/Services/Welding servicies on the piping prefabrication facility.png'),
                        service2nd: require('../../assets/Services/Welding servicies on the oil rig platform.png')
                },
            }
        }
    }
</script>

<style>
@import './Services.css';
</style>