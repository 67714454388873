<template>
    <section id="trusted-us">
      <div class="wrap trusted">
          <h2>Trusted us</h2>
          <Carousel :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="slide in images" :key="slide">
              <img :src="slide.slideImg" :alt="slide.altText" class="clients-logo" />
            </Slide>
            <template #addons>
            <Pagination />
            </template>
         </Carousel>
      </div>
    </section>
</template>

<script>
    import 'vue3-carousel/dist/carousel.css';
    import { Carousel, Slide, Pagination } from 'vue3-carousel';

    export default {
        name: 'TrustedUs',
        components: {
            Carousel,
            Slide,
            Pagination
        },

          data() {
                      return {
                          images: {
                              AkerSolutions: {
                                slideImg: require('../../assets/Clients/Aker Solutions logo.svg'),
                                altText: "Aker Solutions logo"
                              },
                              Kvaerner: {
                                slideImg: require('../../assets/Clients/Kvaerner logo.svg'),
                                altText: "Kvaerner logo"
                              },
                              Konecranes: {
                                slideImg: require('../../assets/Clients/Konecranes logo.svg'),
                                altText: "Konecranes logo"
                              },
                              WestconYards: {
                                slideImg: require('../../assets/Clients/Westcon yards logo.svg'),
                                altText: "Westcon Yards logo"
                              },
                              RRPowerProjects: {
                                slideImg: require('../../assets/Clients/RR Power Projects logo.png'),
                                altText: "RR Power Projects logo"
                              },
                              HMR: {
                                slideImg: require('../../assets/Clients/HMR logo.png'),
                                altText: "HMR logo"
                              },
                              STXFinland: {
                                slideImg: require('../../assets/Clients/STX Finland logo.png'),
                                altText: "STX Finland logo"
                              },
                              MCE: {
                                slideImg: require('../../assets/Clients/MCE logo.svg'),
                                altText: "MCE logo"
                              },
                              EPG: {
                                slideImg: require('../../assets/Clients/EMGP logo.png'),
                                altText: "Energomontaż Północ Gdynia logo"
                              },
                              KBPomorze: {
                                slideImg: require('../../assets/Clients/KB Pomorze logo.png'),
                                altText: "KB Pomorze logo"
                              },
                              Vistal: {
                                slideImg: require('../../assets/Clients/Vistal logo.png'),
                                altText: "Vistal logo"
                              },
                              Mostostal: {
                                slideImg: require('../../assets/Clients/Mostostal Pomorze logo.png'),
                                altText: "Mostostal logo"
                              },
                              CRIST: {
                                slideImg: require('../../assets/Clients/CRIST Offshore logo.png'),
                                altText: "CRIST logo"
                              }
},

                          settings: {
                              itemsToShow: 1,
                              snapAlign: 'center',
                          },

                          breakpoints: {
                              700: {
                                  itemsToShow: 2,
                                  snapAlign: 'start',
                              },

                              950: {
                                  itemsToShow: 3,
                                  snapAlign: 'center',
                              },

                              1440: {
                                  itemsToShow: 4,
                                  snapAlign: 'start',
                              }
                          }
                      }
          },
    }
</script>

<style src="./TrustedUs.css"></style>